.Quote{
    .QuoteText{
        font-family: $fourthFont;
        h3{
            color: $fontColor1;
            font-size: 33px;
            line-height: 1.3;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
            @include media-breakpoint-between(md, lg) {
                font-size: 28px;
            }
            @include media-breakpoint-between(lg, xxl){
                font-size: 30px;
            }
        }
        h4{
            color: $fifthColor;
            text-transform: uppercase;
            font-size: 14px;
            position: relative;
            padding-top: 30px;
            margin-top: 45px;
            &:before{
                position: absolute;
                content: "";
                background: #dcdbdb;
                width: 20%;
                height: 1px;
                top: 0;
            }
        }
    }
}

.QuoteMargin{
    padding: 160px 0;
    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }
    @include media-breakpoint-between(md, xl) {
        padding: 80px 0;
    }
}