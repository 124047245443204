.serviceIntro{
    padding: 80px 0 80px 0;
    @include media-breakpoint-down(md){
        padding: 60px 0;
    }
    @include media-breakpoint-between(md, lg){
        padding: 60px 0;
    }
    @include media-breakpoint-between(lg, xl){
        padding: 70px 0;
    }
    @include media-breakpoint-between(xl, xxl){
        padding: 70px 0;
    }
    .serviceIntroText{
        font-family: $fourthFont;
        color: $fontColor1;
        h2{
            font-size: 25px;
            margin-bottom: 30px;
            line-height: 1.4;
            @include media-breakpoint-down(md){
                font-size: 20px;
                text-align: left;
            }
            @include media-breakpoint-between(md, xxl){
                font-size: 22px;
            }
        }
        p{
            font-size: 17px;
            margin-bottom: 30px;
            @include media-breakpoint-down(md){
                font-size: 16px;
                text-align: left;
            }
            @include media-breakpoint-between(md, lg){
                font-size: 16px;
            }
            &:nth-last-child(1){
                margin-bottom: 0;
            }
        }
    }
}