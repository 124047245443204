.TextModuleLeftImage{
    background: #faf9f9;
}

.TextModuleLeftImageMargin {
    padding: 140px 0;
}

.textModule2{
    h2{
        font-family: $tertiaryFont;
        font-size: 79px;
        color: $fontColor1;
        @include media-breakpoint-only(md){
            font-size: 60px;
        }
        i{
            font-family: $tertiaryFont;
            color: #2f76b4;
            font-style: normal;
            display: block;
        }
    }
    p{
        font-family: $fourthFont;
        font-size: 18px;
        color: $fontColor1;
        margin-bottom: 30px;
    }
}

.textModule2Margin{
    h2{
        margin-bottom: 40px;
    }
}

@include media-breakpoint-down(md){
    .TextModuleLeftImageMargin{
        padding:60px 0;
    }
    .textModule2{
        h2{
            font-size: 50px;
        }
        p{
            font-size: 16px;
            margin-bottom: 15px;
            br{
                display: none;
            }
        }
    }
}

@include media-breakpoint-between(md, lg){
    .TextModuleLeftImageMargin{
        padding: 80px 0;
    }
    .textModule2{
        h2{
            font-size: 40px;
        }
        p{
            font-size: 16px;
        }
    }
}

@include media-breakpoint-only(lg){
    .textModule2{
        h2{
            font-size: 50px;
        }
        p{
            font-size: 16px;
        }
    }
}

@include media-breakpoint-up(xxl){}