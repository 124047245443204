.PageLoader{
    height: 400px;
    background: #fff;
    transition: 1s;
}

.SmallPageLoader{
    height: 100px;
}

.paddingTopHeader{
    padding-top: 100px;
    @include media-breakpoint-down(md){
        padding-top: 65px;
    }
    @include media-breakpoint-between(md, lg){
        padding-top: 85px;
    }
}

a{
    color: #2f76b4;
}

.imgMagnify{
    // overflow: hidden;
    img{
        // transform: scale(1);
        // transition: 5s;
        width: 100%;
        // &:hover{
            // transform: scale(1.2) rotate(4deg);
            // transition: 10s;
        // }
    }
}

.imgMagnifyWidthAuto{
    img{
        width: auto;
        @include media-breakpoint-down(md){
            width: 100%;
        }
        @include media-breakpoint-between(md, lg){
            width: 100%;
        }
        @include media-breakpoint-between(lg, xxl){
            width: 100%;
        }
    }
}

.HomeLogos{
    .logoBox{
        text-align: center;
        img{
            width: 100%;
        }
    }
    .row{
        .col-xl-3{
            &:nth-last-child(1), 
            &:nth-last-child(2), 
            &:nth-last-child(3), 
            &:nth-last-child(4){
                .logoBox{
                    img{
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

.ServiceLogosMargin{
    padding-bottom: 120px;
    @include media-breakpoint-between(md, lg){
        padding-bottom: 80px;
    }
}

.HomeLogosMargin{
    padding:140px 0;
}

.textModule4{
    h2{
        font-family: $tertiaryFont; 
        font-size: 47px;
        color: $fontColor1;
        @include media-breakpoint-down(md){
            font-size: 32px;
        }
    }
    a.btnUnderline{
        &.btnBordered{
            border:1px solid rgba(0, 0, 0, 0.4);
            padding: 28px 54px;
            transition: 0.5s;
            @include media-breakpoint-down(lg){
                padding: 14px 24px;
            }
            &:hover{
                border:1px solid rgba(0, 0, 0, 0.8) !important;
                background: rgba(0, 0, 0, 0.8);
                color: #fff;
                transition: 0.5s;
            }
        }
        font-family: $fourthFont;
        color: $fontColor1;
        font-size: 15px;
        border-bottom: 1px solid rgba(0,0,0,0.4);
        display: inline-block;
        text-decoration: none;
        &:hover{
            border-bottom: 1px solid transparent;
            color: $fontColor1;
        }
    }
}


.Tabs{
    .nav-tabs{
        // border-bottom: 1px solid #d7d7d7;
        a{
            font-family: $fourthFont;
            color: $fontColor1;
            border:none;
            margin-bottom: 0;
            padding: 40px 0;
            @include media-breakpoint-down(md){
                padding: 20px 0;
            }
            &.active{
                font-weight: 800;
                border-bottom: 2px solid $secondaryColor;
            }
        }
    }
}

.textLeft{
    p{
        margin-bottom: 0;
    }
}

.animeGround{
    overflow: hidden;
}

.VideoModal{
    .modal-dialog {
        max-width: 900px;
        transform: translate(0, 30%) !important;
    }
    iframe{
        margin-bottom: -6px;
    }
}
.mainFrame{
    width: 100%;
    height: 100%;
}
.close{
    position: absolute;
    top: -40px;
    right: -13px;
    border-radius: 100%;
    background: transparent;
    border:none;
    width: 18px;
    height: 18px;
    @include media-breakpoint-down(md){
        right: 10px;
    }
    svg{
        position: relative;
        top: -3px;
        width: 18px;
        height: 18px;
    }
}

.showHeader{
    #Header{
        opacity: 1;
        visibility: visible;
        transform: 0.5s;
    }
}

.hideHeader{
    #Header{
        opacity: 0;
        visibility: hidden;
        transform: 0.5s;
    }
}

.hideFooter{
    .Footer{
        display: none;
    }
}

@include media-breakpoint-down(md){
    .container{
        padding: 0 25px !important;
    }
    .textModule4.HomeLogosMobile{
        h2{
            font-size: 22px;
            padding: 22px;
        }
    }
    .HomeLogosMargin {
        padding: 40px 0;
    }
    .ServiceLogosMargin{
        padding-bottom: 70px;
    }

    .HomeLogos{
        img{
            width: 100%;
        }
    }
}

@include media-breakpoint-between(md, lg){
    .textModule4{
        h2{
            font-size: 32px;
        }
    }
    .HomeLogosMargin{
        padding: 80px 0;
    }
}

@include media-breakpoint-between(lg, xxl){
    .textModule4{
        h2{
            font-size: 32px;
        }
    }
    .HomeLogosMargin{
        padding: 100px 0;
    }
    .ServiceLogosMargin{
        padding-bottom: 100px;
    }
}

@include media-breakpoint-up(xxl){}


.BannerBoxAfter{
    position: relative;
    &:after{
        position: absolute;
        content: "";
        background:#fff;
        width: 50%;
        height: 110px;
        left: 0;
        bottom: 0;
        @include media-breakpoint-down(md){
            height: 45px;
        }
    }
}


/* Reveal block */
.reveal-holder {
    position: relative;
    // display: inline-block;
    overflow: hidden;
    .reveal-block {
      position: absolute;
      top: 0;
      width: 100%;
      height: 101%;
      background: rgb(236, 233, 229);
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(236, 233, 229);
        transition-property: transform;
        transition-duration: 1s;
      }
      img{
          transform: scaleX(1.2);
      }
    }
  }
  
  .reveal-block.gradient::before {
    background: linear-gradient(to left, rgb(236, 233, 229), rgb(236, 233, 229));
  }
  
  .reveal-block.gradient-two::before {
    background: linear-gradient(to left, rgb(236, 233, 229), rgb(236, 233, 229));
  }
  
  .reveal-block.extra {
    background: rgb(236, 233, 229);
    &::before {
      background: rgb(236, 233, 229);
    }
  }
  
  .reveal-block.top.aos-animate::before,
  .reveal-block.bottom.aos-animate::before {
    transform: scaleY(1);
  }
  
  .reveal-block.left.aos-animate::before,
  .reveal-block.right.aos-animate::before {
    transform: scaleX(1);
  }
  
  .reveal-block.top::before {
    transform: scaleY(0);
    transform-origin: 0% 100%;
  }
  
  .reveal-block.left::before {
    transform: scaleX(0);
    transform-origin: 100% 0%;
  }
  
  .reveal-block.right::before {
    transform: scaleX(0);
    transform-origin: 0% 100%;
  }
  
  .reveal-block.bottom::before {
    transform: scaleY(0);
    transform-origin: 100% 0%;
  }
  
  /* AOS animate */
  [data-aos="reveal-top"],
  [data-aos="reveal-left"],
  [data-aos="reveal-right"],
  [data-aos="reveal-bottom"] {
    transition-property: transform;
    transition-delay: 1s;
  }
  
  [data-aos="reveal-top"] {
    transform: scaleY(1);
    &.aos-animate {
      transform: scaleY(0);
      transform-origin: 100% 0%;
    }
  }
  
  [data-aos="reveal-left"] {
    transform: scaleX(1);
    &.aos-animate {
      transform: scaleX(0);
      transform-origin: 0% 100%;
    }
  }
  
  [data-aos="reveal-right"] {
    transform: scaleX(1);
    &.aos-animate {
      transform: scaleX(0);
      transform-origin: 100% 0%;
      img{
        transform: scale(1);
        }
    }
  }
  
  [data-aos="reveal-bottom"] {
    transform: scaleY(1);
    &.aos-animate {
      transform: scaleY(0);
      transform-origin: 0% 100%;
    }
  }
  
  [data-aos="reveal-item"] {
    visibility: hidden;
    transition-property: visibility;
    transition-duration: 0s;
    &.aos-animate {
      visibility: visible;
    }
  }

  #mobile-call-btn {
    position: fixed;
    bottom: 83px;
    right: 22px;
    z-index: 11;
    background-color: #00445c;
    width: 50px;
    padding: 14px;
    text-align: center;
    height: 50px;
    border-radius: 40px;
    img{
        width: 20px;
        top: 14px;
        right: 14px;
        filter: invert(1);
        position: absolute;
    }
}

#whatsapp-msg-btn {
    position: fixed;
    bottom: 25px;
    right: 22px;
    z-index: 11;
    background-color: #4dc247;
    width: 50px;
    text-align: center;
    height: 50px;
    border-radius: 40px;
    img{
        width: 28px;
        filter: invert(1);
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #2f76b4;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

.ThankYou{
    padding-top: 180px !important;
}

.LoadMoreSpan{
    font-family: $tertiaryFont;
    color: $fontColor1;
    font-size: 28px;
    text-align: center;
    display: block;
    cursor: pointer;
}

.moreBlogs{
    .col-xl-6{
        &:nth-child(9n+1){
            display: none;
        }
    }
}

.Hero{
    .MuiSkeleton-root{
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }
}

.corporate-social-responsibility{
    @include media-breakpoint-down(md){
        .textLeft{
            .container{
                .row{
                    .col-md-5{
                        margin-left: -14px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
    .keypointsImage{
        min-height: 670px;
        @include media-breakpoint-between(md, lg){
            min-height: 440px;
        }
        @media (min-width:992px) and (max-width:1199px){
            min-height: 350px;
        }
        @media (min-width:1200px) and (max-width:1450px){
            min-height: 420px;
        }
        @media (min-width:1451px) and (max-width:1650px){
            min-height: 520px;
        }
        @include media-breakpoint-down(md){
            min-height: auto;
            img.wp-image-2275{
                width: 90%;
                height: 70px;
            }
        }
        @include media-breakpoint-between(md, lg){
            img.wp-image-2275{
                width: 80%;
                height: 80px;
            }
        }
        @include media-breakpoint-between(lg, xl){
            img.wp-image-2275{
                width: 60%;
                height: 70px;
            }
        }
        @include media-breakpoint-between(xl, xxl){
            img.wp-image-2275{
                width: 60%;
                height: 70px;
            }
        }
    }
    .SimpleCTA{
        .container{
            margin-bottom: 0;
        }
    }
    .textLeft_content{
        h2{
            i{
                display:inline !important;
                @include media-breakpoint-between(md, lg){
                    display: inline !important;
                }
            }
        }
    }
}
.txtBtn{
    color: #303951;
    font-family: $fourthFont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        color: #303951;
    }
}
.topPadding{
    padding-top: 120px;
    @include media-breakpoint-down(md){
        padding-top: 80px;
    }
}
.topPadding2{
    padding-top: 180px;
    @include media-breakpoint-down(md){
        padding-top: 80px;
    }
}
.bottomPadding{
    padding-bottom: 120px;
    @include media-breakpoint-down(md){
        padding-bottom: 80px;
    }
}
.bottomPaddingImportant{
    padding-bottom: 120px!important;
}
// .PaddingTopHeding{
//     min-height: 800px;
// }

a.btnUnderlineBig{
    &.btnBorderedBig{
        border:1px solid rgba(0, 0, 0, 0.4);
        padding: 28px 54px;
        transition: 0.5s;
        @include media-breakpoint-down(lg){
            padding: 14px 24px;
        }
        &:hover{
            border:1px solid rgba(0, 0, 0, 0.8) !important;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            transition: 0.5s;
        }
    }
    font-family: $fourthFont;
    color: $fontColor1;
    font-size: 24px;
    border-bottom: 1px solid rgba(0,0,0,0.4);
    display: inline-block;
    text-decoration: none;
    @include media-breakpoint-down(md){
        margin: 0 40px;
        font-size: 22px;
    }
    &:hover{
        border-bottom: 1px solid transparent;
        color: $fontColor1;
    }
}

.PremiumFeatures{
    background: #F7F7F7;
}

.landmarkSec{
    background: #F7F7F7;
}

.pfBox{
    img{
        mix-blend-mode: darken;
    }
}

.amenBox{
    span{
        color: #303951;
        font-family: $fourthFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @include media-breakpoint-down(md){
            font-size: 18px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 18px;
        }
        @include media-breakpoint-between(lg, xl){
            font-size: 18px;
        }
    }
}

.rowAB{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-between(md, xl){
        justify-content: space-between;
    }
    .colImgAB{
        width: 21%;
        text-align: center;
        img{
            @include media-breakpoint-down(md){
                width: 44px;
            }
        }
    }
    .colLabelAB{
        width: 70%;
        @include media-breakpoint-between(sm, md){
            width: 65%;
            margin-left: 5%;
        }
    }

}

@media (min-width:576px) and (max-width:767.98){
    .colLabelAB{
        width: 65%;
    }
}

.AmentiesList{
    .row{
        .col-sm-6{
            margin-bottom:30px;
        }
    }
}

.ContactBanner{
    padding: 340px 0 60px 0;
    @include media-breakpoint-down(md){
        background-position: center;
        padding-top: 120px;
    }
    .textModule1{
        h2{
            color: #fff;
        }
    }
}

.cdLabel:nth-last-child(1) img{
    width: 17px;
}

.cdLabel{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    span{
        color: #FFF;
        font-family: $fourthFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        width: 80%;
        @include media-breakpoint-down(md){
            font-size:18px;
        }
    }
    a{
        color: #fff;
        text-decoration: none;
        &:hover{
            color: #fff;
        }
    }
    img{
        width: 25px;
    }
    .cdLabelText{
        color:#303951;
        font-family: $fourthFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        width: 80%;
        @include media-breakpoint-down(md){
            font-size: 16px;
        }
        a{
            color: #303951;
            border-bottom: 1px solid #303951;
            text-decoration: none;
            &:hover{
                border-bottom: 1px solid transparent;
                color: #303951;
                text-decoration: none;
            }
        }
    }
}

.arrowPoints{
    ul{
        list-style: none;
        padding-left: 30px;
        font-family: $fourthFont;
        font-size: 18px;
        color: #303951;
        margin-bottom: 0;
        @include media-breakpoint-down(md){
            font-size: 16px;
            margin-bottom: 0;
        }
        li{
            position: relative;
            &::before{
                position: absolute;
                content: "";
                background: url(../media/check-sign.svg) no-repeat;
                background-size: cover;
                width: 14px;
                height: 11px;
                top: 7px;
                left: -30px;
            }
        }
    }
}

.spBox{
    border-radius: 5px;
    background: #F7F7F7;
    padding: 30px 30px;
    @include media-breakpoint-down(md){
        padding:30px 50px 30px 30px;
    }
    span{
        color: #303951;
        font-family: $tertiaryFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 15px;
        display: block;
    }
}

.clKP_mb{
    margin-left: -20px;
    margin-right: -20px;
    .col-12{
        margin-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
            &:nth-last-child(1){
                margin-bottom: 0;
            }
        }
        
    }
}

.customTabs.nav-tabs{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: none;
    a{
        background: url(../media/tab-arrow.svg) no-repeat #F7F7F7;
        text-align: left !important;
        color: #303951;
        font-family: $tertiaryFont;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 30px 30px;
        border-radius: 0;
        background-size: 30px;
        background-position: center right 30px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include media-breakpoint-down(md){
            padding: 30px 40px 30px 10px;
            background-size:20px;
            background-position: center right 20px;
            font-size: 20px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 16px;
            padding: 16px 20px;
        }
        &:hover{
            background:  url(../media/tab-arrow-hover.svg) no-repeat #1561B0;
            background-size: 30px;
            background-position: center right 30px;
            color: #fff;
            @include media-breakpoint-down(md){
                background-size:20px;
                background-position: center right 20px;
            }
            svg{
                path{
                    fill:#fff
                }
            }
        }
        &.active{
            background:  url(../media/tab-arrow-hover.svg) no-repeat #1561B0;
            background-size: 30px;
            background-position: center right 30px;
            color: #fff;
            @include media-breakpoint-down(md){
                background-size:20px;
                background-position: center right 20px;
            }
            svg{
                path{
                    fill:#fff
                }
            }
        }
        svg{
            width: 30px;
        }
    }
}

.pfBox{
    padding: 0 60px 0 0;
    @include media-breakpoint-down(md){
        padding: 0;
    }
    h4 {
        color: #2560A5;
        font-family: $fourthFont;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    p{
        color: #303951;
        font-family: $fourthFont;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.AmentiesList{
    .row{
        .col-md-4{
            &:nth-last-child(n+4){
                margin-bottom: 40px;
            }
        }
    }
}

.TopContent{
    .textModule2{
        p{
            text-align: center;
            @include media-breakpoint-down(md){
                text-align: left;
            }
        }
    }
}

.LocationsSlider{
    .container{
        @include media-breakpoint-down(md){
            width: 100%;
            padding: 0 !important;
        }
    }
}

.remove-pb-mobile{
    @include media-breakpoint-down(md){
        padding-bottom: 0;
    }
}

.remove-pt-mobile{
    @include media-breakpoint-down(md){
        padding-top: 0;
    }
}

.ContactFooter{
    iframe{
        @include media-breakpoint-down(md){
            margin-bottom: -8px;
        }
    }
}

.customSlickNav{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin-top: 50px;
    .slick-prev{
        background: url(../media/left-arrow-proc.svg) no-repeat;
        left: 0;
        &:before{
            display: none;
        }
    }
    .slick-next{
        background: url(../media/right-arrow-proc.svg) no-repeat;
        right: 0;
        &:before{
            display: none;
        }
    }
    .slick-arrow{
        width: 44px;
        height: 18px;
        top: inherit;
        transform: inherit;
        position: relative;
    }
}

.ThreeBlockSlider{
    .slick-slide{
        .ThreeBlock{
            margin-left: 0;
            @include media-breakpoint-up(md){
                margin-left: 40px;
            }
            .descThreeBlockMargin{
                margin-top: 30px !important;
            }
            a{
                color: #303951;
                text-decoration: none;
                &:hover{
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
    }
}

.ThreeBlockSliderSec{
    padding: 80px 0;
}

.topMargin{
    margin-top: 140px;
    @include media-breakpoint-down(md){
        margin-top: 0;
    }
}

.bottomPaddingMobile{
    padding-bottom: 0;
    @include media-breakpoint-down(md){
        padding-top: 80px;
    }
}

.simpleFAQs{
    .accordion{
        .accordion-item{
            .accordion-header{
                button{
                    font-weight: 400;
                }
            }
        }
    }
}


.testiBox{
    p {
        color: #303951;
        text-align: center;
        font-family: 'pnr';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */
    }
    span {
        color: #2560A5;
        font-family: 'pnr';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        display: block;
        margin-top: 40px;
    }
    em {
        color: #303951;
        text-align: center;
        font-family: 'pnr';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */
        display:block;
        margin:5px 0;
    }
}