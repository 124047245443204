.locBox{
    .locThumb{
        img{
            &:nth-child(1){
                transition: 0.5s;
                &:hover{
                    opacity: 0;
                }
            }
        }
    }
    a{
        font-family: $tertiaryFont;
        font-size: 30px;
        color: $fontColor1;
        text-decoration: none;
        &:hover{
            color:$primaryColor;
        }
    }
}

.LocationBoxMargin{
    padding: 120px 0;
}

@include media-breakpoint-down(md){
    .LocationBoxMargin{
        padding: 60px 0;
    }
    .LocationBox{
        .textModule4 h2{
            padding: 0;
        }
    }
    .locBox{
        a{
            font-size: 18px;
        }
    }
}

@include media-breakpoint-between(md, lg){
    .LocationBoxMargin{
        padding: 60px 0;
    }
    .locBox{
        a{
            font-size: 22px;
        }
    }
}

@include media-breakpoint-between(lg, xxl){}

@include media-breakpoint-up(xxl){}