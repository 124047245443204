.Header{
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    padding:15px 0;
    z-index: 2;
    transition: 0.5s;
    .navbar-brand{
        .MuiSkeleton-pulse{
            @include media-breakpoint-down(md){
                margin-top: 0 !important;
            }
        }
    }
    &.changeHeader{
        height: 100%;
        background: #fff;
        .Navbar{
            .navbar-nav{
                display: block;
                a{
                    margin: 0 30px 15px 30px;
                    display: block;
                    @include media-breakpoint-down(md){
                        margin: 0;
                    }
                }
                .submenuMobile{
                    li{
                        a{
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
        .menu-primary-container{
            width: 100%;
        }
    }
    &.changeHeader{
        .mobileHeader{
            display: block !important;
            .mobileSocialIcons{
                ul{
                    &.socialIcons{
                        margin-top: 300px;
                    }
                    li{
                        a{
                            display: inline-block;
                            margin:0 8px;
                            &:nth-child(1){
                                margin-left: 0;
                            }
                            svg{
                                width: 16px;
                                height: 16px;
                                path{
                                    fill:rgba(0,0,0,0.8) !important;
                                    &:hover{
                                        fill: rgb(255,255,255) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #mobileMenu{
            display: block !important;
            .navbar{
                margin-top: 160px;
                @include media-breakpoint-up(md){
                    margin-top: 0;
                }
            }
            ul{
                li{
                    a{
                        display: block;
                        color: $fontColor1;
                        padding: 0 25px 10px 25px;
                        text-decoration: none;
                        margin-bottom: 0;
                        border-bottom: 1px solid transparent !important;
                        transition: 0.5s;
                        font-size: 24px;
                        margin-bottom: 10px;
                        &.ParentActive{
                            color: #000;
                            font-weight: 800;
                            transition: 0.5s;
                            img{
                                transform: rotate(180deg);
                                transition: 0.5s;
                            }
                        }
                        img {
                            opacity: 0.3;
                            width: 12px;
                            position: relative;
                            top: -1px;
                            right: -10px;
                            transition: 0.5s;
                        }
                    }
                }
            }
        }
    }
    .Navbar{
        .navbar-brand{
            img{
                width: 100%;
            }
        }
        .navbar-nav{
            flex-direction: row;
            li{
                position: relative;
                a{
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    text-decoration: none;
                    margin: 0 40px;
                    &:hover{
                        color: #333;
                        border-bottom: 1px solid $secondaryColor;
                    }
                }
                .submenuSmall {
                    list-style: none;
                    margin: 0;
                    position: absolute;
                    top: 45px;
                    left: 40px;
                    background: rgba(20, 30, 42, 0.97);
                    width: 180px;
                    padding: 15px 15px;
                    @include media-breakpoint-down(xxl){
                        left: 20px;
                    }
                    &.submenuSmallActive{
                        opacity: 1;
                        display: block !important;
                        transition: 0.3s;
                    }
                    li{
                        a{
                            margin: 0;
                            text-align: left;
                            color: #fff;
                            margin-bottom: 10px;
                            display: inline-block;
                            &:hover{
                                border-bottom: 1px solid #88c6fc;
                                color: #88c6fc;
                            }
                            @include media-breakpoint-between(lg, xxl){
                                margin: 0 !important;
                            }
                        }
                        &:nth-last-child(1){
                            a{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .header_right{
        a{
            font-family: $secondaryFont;
            background: $secondaryColor;
            font-size: 16px;
            color: #fff;
            text-decoration: none;
            line-height: 1;
            padding: 18px 35px;
            border-radius: 5px;
            transition:0.5s;
            &:hover{
                background: #797a7c;
                transition:0.5s;
                border-radius: 0;
            }
        }
    }
}

html {
    scroll-behavior: smooth;
  }
.blueHeader{
    background:rgba(20, 30, 42, 0.97);
    transition: 0.5s;
    .Navbar{
        .navbar-nav{
            a{
                color: #fff !important;
            }
        }
    }
    .navbar-brand{
        img{
            &.headerColorLogo{
                visibility: hidden;
                display:none;
                transition: 0.5s;
            }
            &.headerWhiteLogo{
                visibility: visible;
                display: block !important;
                transition: 0.5s;
                position: relative;
                top: 16px;
            }
            // filter: brightness(100);
        }
    }
}



.subMenu{
    height: 0px;
    overflow: hidden;
    transition: 0.5s;
    &.openSubMenu{
        transition: 0.5s;
        height: 500px !important;
    }
    ul{
        margin: 100px 0 120px 0;
        list-style: none;
        li{
            a{
                font-family: $tertiaryFont;
                font-size: 30px;
                color: #fff;
                text-decoration: none;
                @include media-breakpoint-between(md, xxl){
                    font-size: 24px;
                }
                &:hover{
                    color: #88c6fc;
                }
            }
        }
    }
}

.nav-up{
    opacity: 0;
    transition: 0.5s;
}

.nav-down{
    opacity: 1;
    transition: 0.5s;
}

.submenuMobile{
    &.submenuMobileActive{
        display: block !important;
        list-style: none;
        background: #f1f1f1;
        padding: 15px 0 0 0;
        margin-bottom: 15px;
    }
}

@include media-breakpoint-down(md){
    .Header{
        background:#fff;
        &:after{
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, #fff 0%, transparent 100%);
            top: 0;
            z-index: -1;
            display: none;
        }
        .navbar-brand{
            img{
                width: 100%;
            }
        }
        .navbar-toggler{
            box-shadow: none !important;
            .navbar-toggler-icon{
                height:1px;
                width: 22px;
                background-color:#000;
                position: relative;
            }
        }
        .navbar-toggler-icon{
            &:before{
                position: absolute;
                content: "";
                background: #000;
                height: 1px;
                width: 100%;
                top: -7px;
                left: 0;
                transition: 0.5s;
            }
            &:after{
                position: absolute;
                content: "";
                background: #000;
                height: 1px; 
                width: 100%; 
                bottom: -7px; 
                left: 0; 
                transition: 0.5s;
            }
        }
        .navbar-open{
            .navbar-toggler-icon{
                background: transparent;
                top:5px;
                &:before{
                    transform: rotate(135deg);
                    transition: 0.5s; 
                    width: 90%;
                }
                &:after{ 
                    transform: rotate(-135deg); 
                    bottom: 7px; 
                    width: 90%; 
                    transition: 0.5s;
                }
            }
        }
    }
}

@include media-breakpoint-between(md, lg){
    .Header{
        .Navbar{
            .navbar-nav{
                a{
                    font-size: 16px;
                    margin: 0 10px !important;    
                }
            }
        }
        .navbar-brand{
            img{
                width: 100%;
            }
        }
        .header_right{
            a{
                padding: 12px 20px;
            }
        }
    }
}

@include media-breakpoint-between(lg, xxl){
    .Header{
        .Navbar{
            .navbar-nav{
                a{
                    margin:0 20px !important;
                }
            }
        }
        .header_right{
            a{
                padding: 14px 25px;
            }
        }
    }
    .navbar-brand{
        img{
            width: 100%;
        }
    }
}

@include media-breakpoint-up(xxl){}



// @include media-breakpoint-between(md, lg){}

// @include media-breakpoint-between(lg, xxl){}

// @include media-breakpoint-up(xxl){}