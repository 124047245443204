.keypointsImage{
    // min-height: 870px;
    margin:90px 0 0 0;
    @include media-breakpoint-down(md){
        height: auto;
        min-height: auto;
        margin:60px 0 0 0;
    }
    // @include media-breakpoint-between(md, lg){
    //     min-height: 670px;
    // }
    // @include media-breakpoint-between(lg, xl){
    //     min-height: 700px;
    // }
    // @include media-breakpoint-between(xl, xxl){
    //     min-height: 750px;
    // }
    // @media (min-width:1400px) and (max-width:1550px){
    //     min-height: 690px;
    // }
    // @media (min-width:1551px) and (max-width:1700px){
    //     min-height: 760px;
    // }
    // &.keypointsImageLeftMargin{
    //     min-height: 680px;
    // }
    &.keypointsImageLeft{
        .ctaImg{
            right: inherit;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            @include media-breakpoint-between(md, lg){
                width: 45%;
            }
            @include media-breakpoint-down(md){
                transform: translate(0, 0);
                top: inherit;
            }
        }
        // @media (min-width: 1401px) and (max-width: 1550px){
        //     min-height: 745px;
        // }
    }
    .container{
        min-height: inherit;
        .row{
            min-height: inherit;
        }
    }
}

.ctaAbs{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
}

.ctaRightBox{
    background: #2560A5;
    padding: 100px 0;
    @include media-breakpoint-down(md){
        padding-left:25px;
        padding-right: 25px;
    }
    @include media-breakpoint-between(md, lg){
    }
    @include media-breakpoint-between(lg, xxl){
    }
    .accordion-item{
        background-color: #fff!important;
        margin-bottom: 15px !important;
    }
}

.KeyPointsList{
    .row{
        margin-bottom: 50px;
        @include media-breakpoint-down(md){
            margin-bottom: 20px;
        }
        @include media-breakpoint-between(md, lg){
            margin-bottom: 20px;
        }
        @include media-breakpoint-between(lg, xxl){
            margin-bottom: 25px;
        }
        &:nth-last-child(1){
            margin-bottom: 0;
        }
    }
    h3{
        font-family: $fourthFont;
        font-size: 24px;
        color: $fontColor1;
        font-weight: 800;
        margin-bottom: 20px;
        @include media-breakpoint-down(md){
            font-size: 20px;
            margin-bottom: 10px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 16px;
            margin-bottom: 10px;
        }
        @include media-breakpoint-between(lg, xxl){
            font-size: 18px;
        }
    }
    p{
        font-family: $fourthFont;
        font-size: 17px;
        color: $fontColor1;
        @include media-breakpoint-down(md){
            font-size: 16px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 16px;
        }
    }
    span{
        font-family: $tertiaryFont;
        font-size: 79px;
        color: #2f76b4;
        line-height: 0.5;
        @include media-breakpoint-down(md){
            font-size: 49px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 39px;
        }
    }
}

.ThreeBlocks.ThreeBlocksMargin{
    padding-top: 80px;
}

.ctaImg{
    position: relative;
    left: 120px;
    top: 60px;
    @include media-breakpoint-down(md){
        top: 0;
        left: 0;
    }
    @include media-breakpoint-between(md, lg){
        top: 60px;
        left: 40px;        
    }
    @include media-breakpoint-between(lg, xxl){
        top: 60px;
        left: 40px;
    }
    // width: 40%;
    // top: 0;
    // right: 0;
    // @include media-breakpoint-down(md){
    //     position: relative;
        // width: 100%;
        // margin-top: 20px;
    // }
    // @include media-breakpoint-between(md, lg){
        // width: 50%;
    // }
    img{
        width: 100%;
    }
    .ctaImgText{
        background: #141e2a;
        padding: 50px 50px;
        @include media-breakpoint-down(md){
            padding: 30px;
        }
        @include media-breakpoint-between(md, lg){
            padding: 30px;
        }
        h4{
            font-family: $fourthFont;
            font-size: 23px;
            color: #fff;
            margin-bottom: 20px;
        }
        p{
            font-family: $fourthFont;
            font-size: 16px;
            color: #fff;
            margin-bottom: 0;
            br{
                @include media-breakpoint-between(md, lg){
                    display: none;
                }
            }
        }
        a{
            font-family: $secondaryFont;
            background: #2f76b4;
            font-size: 16px;
            color: #fff;
            text-decoration: none;
            line-height: 1;
            padding: 18px 30px;
            border-radius: 5px;
            transition: 0.5s;
            display: inline-block;
            &:hover{
                opacity: 0.9;
            }
            @media (min-width: 1350px) and (max-width: 1500px){
                padding: 18px 20px;
            }
        }
    }
}