.whiteFont{
    h2{
        color: #fff;
    }
}

.PartnerHome{
    .partner-bg{
        background-size: cover !important;
        padding:230px 0;
        @include media-breakpoint-only(lg){
            padding: 100px 0;
        }
    }
}

.textModuleWhite{
    h2{
        color: #fff;
        br{
            @include media-breakpoint-down(md){
                display: none;
            }
        }
    }
    h3{
        color: #fff;
    }
}

.LinkArrowWhite{
    a{
        color:#fff;
        @include media-breakpoint-down(md){
            display: flex;
        }
        &:hover{
            color: #f1f1f1;
        }
        img{
            mix-blend-mode: hard-light;
            border-radius: 100%;
            @include media-breakpoint-between(md, lg){
                width: 28px;
            }
            
        }
    }
}

.textCapitalizeh2{
    h2{
        text-transform: capitalize;
    }
}

@include media-breakpoint-down(md){
    .PartnerHome{
        height: 480px;
        .partner-bg{
            height: 100%;
            padding: 60px 0;
            background-position: top right -380px !important;
        }
        .textModule3{
            h3{
                font-size: 12px;
                @include media-breakpoint-down(md){
                    line-height: 1.6;
                }
            }
        }
    }
}

@include media-breakpoint-between(sm, md){
    .PartnerHome{
        .partner-bg{
            background-position: 0 !important;
        }
    }
}

@include media-breakpoint-between(md, lg){
    .PartnerHome{
        .partner-bg{
            padding: 60px 0;
        }
    }
}

@include media-breakpoint-between(lg, xxl){}

@include media-breakpoint-up(xxl){}