.TabMaps{
    .nav{
        .nav-link{
            font-family: $fourthFont;
            color: $fontColor1;
            padding: 30px 0;
            font-size: 16px;
            @include media-breakpoint-up(lg){
                padding: 40px 0;
                font-size: 20px;
            }
            &.active{
                border:none !important;
                border-bottom: 3px solid $secondaryColor !important;
                background: #f9f8f8;
                font-weight: 600;
                &:focus{
                    border-bottom: 3px solid $secondaryColor !important;
                }
                &:hover{
                    border-bottom: 3px solid $secondaryColor !important;
                }
            }
            &:hover{
                border:none !important;
            }
            &:focus{
                border:none !important;
            }
        }
    }
}