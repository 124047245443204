.Hero{
    overflow: hidden;
    height: 100vh;
    h1{
        font-family: $tertiaryFont;
        color: $fontColor1;
        font-size: 90px;
        i{
            color: $secondaryColor;
            font-style: normal;
            // border-bottom:3px solid $secondaryColor;
            display: inline-block;
            position: relative;
            top:-22px;
            left: 30px;
            @include media-breakpoint-down(md){
                top: -13px;
                left: 10px;
            }
            div{
                overflow: hidden;
                em{
                    font-style: normal;
                }
            }
        }
    }
   .reveal-text{
    min-height: 280px;
    // overflow:hidden ;
   }
//    .textSwap {
//     overflow: hidden;
//     display: inline-table;
//    }

    p{
        color: $fontColor1;
        font-size: 25px;
    }
    .heroTextMargin{
        margin:140px 0 85px 0;
        p{
            margin-top: 30px;
            margin-bottom: 0;
        }
    }
    .logoList{
        a:first-of-type{
            margin: 0 15px 0 0 ;
        }
        a{
            display: inline-block;
            margin:0 15px;
            img{
                filter: grayscale(1);
                max-width:113px ;
                width: 113px;
            }
        }

    }
    .heroSlidingImages{
        display:flex;
        flex-wrap:wrap;
        transition:-webkit-transform 3s cubic-bezier(.19,1,.22,1);
        transition:transform 3s cubic-bezier(.19,1,.22,1);
        transition:transform 3s cubic-bezier(.19,1,.22,1),-webkit-transform 3s cubic-bezier(.19,1,.22,1);
        -webkit-transform-style:preserve-3d;
        transform-style:preserve-3d;
        align-items:flex-start;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        width: 46%;
        height: inherit;
        padding-right: 80px;
        z-index: 1;
        margin-top: -32px;
        .col{
            // transform: translate(0, 700px);
            width:50%;
            padding:0 5px;
            transition:-webkit-transform 1s cubic-bezier(.19,1,.22,1);
            transition:transform 1s cubic-bezier(.19,1,.22,1);
            transition:transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1);
            -webkit-animation:c 30s linear infinite;
            animation:c 30s linear infinite;
            -webkit-transform-style:preserve-3d;
            transform-style:preserve-3d;
            a{
                display: block;
                margin-bottom: 10px;
                overflow: hidden;
                border-radius: 10px;
                img{
                    width:100%;
                    transform: scale(1.2);
                    transition: 1s;
                    &:hover{
                        transform: scale(1);
                        transition: 1s;
                    }
                }
                span{
                    position: absolute;
                    display: none;
                }
            }
        }
        .col-two{
            // transform: translate(0, -700px);
            width:50%;
            padding:0 5px;
            transition:-webkit-transform 1s cubic-bezier(.19,1,.22,1);
            transition:transform 1s cubic-bezier(.19,1,.22,1);
            transition:transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1);
            -webkit-animation:c 30s linear infinite reverse;
            animation:c 30s linear infinite reverse;
            -webkit-transform-style:preserve-3d;
            transform-style:preserve-3d;
            a{
                display: block;
                margin-bottom: 10px;
                overflow: hidden;
                border-radius: 10px;
                img{
                    width:100%;
                    transform: scale(1.2);
                    transition: 1s;
                    &:hover{
                        transform: scale(1);
                        transition: 1s;
                    }
                }
                span{
                    position: absolute;
                    display: none;
                }
            }
        }
        .col:nth-last-child(1){
            margin-top: 32%;
        }
    }
    // .isPausedActive{
    //     .col{
    //         -webkit-animation-play-state:paused;
    //         animation-play-state:paused
    //     }
    // }
    @media (max-width:350px){
        h1{
            font-size: 36px !important;
        }
        .heroTextMargin{
            p{
                font-size: 12px !important;
                margin-top: 20px !important;
            }
        }
        .logoList{
            display: flex;
            flex-wrap: wrap;
            a{
                margin: 0 5px 15px 0 !important;
                max-width: 46%;
                text-align: center;
                img{
                    width: 100%;
                }
            }
        }
    }
}
.home-hero-gallery__left.is-paused,.home-hero-gallery__right.is-paused{
    -webkit-animation-play-state:paused;
    animation-play-state:paused
}
@media (min-width:51.875em){
    .home-hero-gallery__left,.home-hero-gallery__right{
        padding:0 10px
    }
}
.home-hero-gallery__right{
    margin-top:-32%
}

// Animation for hero
@-webkit-keyframes c{
    0%{
        tranform:translateY(0)
    }
    to{
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%)
    }
}
@keyframes c{
    0%{
        tranform:translateY(0)
    }
    to{
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%)
    }
}

@include media-breakpoint-down(md){
    .Hero{
        h1{
            font-size: 50px;
        }
        p{
            font-size: 16px;
            br{
                display: none;
            }
        }
        .container{
            height: 100%;
            .row{
                height: 100%;
                justify-content: center;
                align-items: center;
            }
        }
        .heroTextMargin {
            margin: 0;
        }
        .logoList{
            a{
                margin:0 15px 15px 0;
            }
        }
        .heroSlidingImages{
            padding-right: 0;
            margin-top: 0;
            width: 100%;
            top: 0;
            z-index: -1;
            padding: 0 5px;
        }
    }
    .heroMobileBox{
        background:#fff;
        padding: 40px 30px;
        margin: 0 20px 0 20px;
    }
}

@include media-breakpoint-between(md, lg){
    .Hero{
        h1{
            font-size: 60px;
        }
        p{
            font-size: 18px;
        }
        .heroTextMargin{
            margin-bottom: 40px;
        }
        .logoList{
            a{
                margin-bottom: 15px;
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-between(lg, xxl){
    .Hero{
        h1{
            font-size: 60px;
        }
        p{
            font-size: 18px;
        }
        .heroTextMargin{
            margin:140px 0;
        }
        .logoList{
            a{
                img{
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl){}

:root {
    --loader-color: #2f76b4;
    --back-color: #fff;
    --time: 3s;
    --size: 2px;
  }
  
  .homeLoader{
      margin-top: -140px;
  }
  
  .loader {
      background-color: var(--main-color);
      overflow: hidden;
    width: 100%;
    height: 100%;
    // position: fixed;
    top: 0; left: 0;
    display: flex;
    align-items: center;
    align-content: center; 
    justify-content: flex-start;  
      z-index: 100000;
  }
  
  .loader__element {
      height: var(--size);
      width: 100%;
      background: var(--back-color);
  
  }
  
  .loader__element:before {
    content: '';
    display: block;
    background-color: var(--loader-color);
    height: var(--size);
    width: 0;
    animation: getWidth var(--time) ease-in-out infinite;
  }
  
  @keyframes getWidth {
        100% { width: 100%; }
  }
  @keyframes getTime {
    0% { width: 0; }
    25% { width: 25%; }
    50% { width: 50%; }
    75% { width: 75%; }
    100% { width: 100%; }
}
[data-aos="fade-out"] {
    opacity: 1;
    transition-property: opacity;
    &.aos-animate {
        opacity: 0;
    }
}

// .homeLoader{
//     transition: 1s;
// }

@media only screen and (max-width:1400px) {
    .reveal-text{
       min-height: 180px;
        // overflow:hidden ;
       }
}

@media screen and (max-width:1200px) {
    .Hero{
    .reveal-text{
        min-height: 120px;
         // overflow:hidden ;
        }
    }
}


@media screen and (max-width:991px) {
    
    .Hero{
    .reveal-text{
        min-height: 180px;
         // overflow:hidden ;
        }
    }
}

@media screen and (max-width:768px) {
    .Hero{
    .reveal-text{
        min-height: 1px;
         // overflow:hidden ;
        }
    }
}




