.BoxColumnsContent{
    padding: 120px 0 120px 0;
    @include media-breakpoint-down(md){
        padding: 40px 0 40px 0;
    }
    @include media-breakpoint-between(md, lg){
        padding: 0 0 0 0;
    }
    @include media-breakpoint-between(lg, xxl){
        padding: 120px 0 0 0;
    }
}

.BoxColumnsText{
    img{
        @include media-breakpoint-down(xl){
            width: 100%;
        }
    }
    &.textModule2{
        h2{
            font-size: 38px;
            margin-bottom: 20px;
            @include media-breakpoint-between(md, lg){
                font-size: 32px;
            }
            @include media-breakpoint-up(xl){
                font-size: 50px;
                margin-bottom: 40px;
            }
        }
        p{
            font-size: 16px;
            margin-bottom: 20px;
            @include media-breakpoint-between(md, lg){
                font-size: 14px;
            }
            @include media-breakpoint-up(xl){
                font-size: 20px;
                margin-bottom: 60px;
            }
        }
    }
}