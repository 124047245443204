.AboutHome{
    background: #fffefe;
    .AboutHomeTextBox{
        width: 500px;
        margin-top: -90px;
    }
}

.AboutHomeMarginTop{
    margin-top: 140px;
}

.AboutHomeMarginTop2{
    margin-top: 60px;
}

.LinkArrow{
    a{
        color:#000;
        font-family: $secondaryFont;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: 0.5s;
        font-size: 16px;
        &:hover{
            color: $fontColor1;
        }
        &:hover{
            img{
                margin-left: 5px;
                transition: 0.5s;
            }
        }
        img{
            margin-left: 15px;
            transition: 0.5s;
            @include media-breakpoint-between(lg, xxl){
                width: 25px;
            }
        }
    }
}

@include media-breakpoint-down(md){
    .AboutHome{
        .AboutHomeTextBox{
            width: 100%;
            margin-top: 0;
        }
        .textModule3{
            h3{
                margin-bottom: 20px;
            }
        }
        .AboutHomeTextBox{
            padding: 0 25px;
        }
    }
    .LinkArrow{
        a{
            margin-top: 10px;
            display: inline-block;
            font-size: 14px;
            img{
                width: 30px;
            }
        }
    }
    .AboutHomeMarginTopMobile{
        margin-top: -15px;
    }
    .AboutHomeMarginTop{
        margin-top: 0;
    }
    .AboutHomeMarginTop2{
        margin-top: 20px;
    }
    .textModule2Margin{
        h2{
            margin-bottom: 20px;
        }
    }
    
}

@include media-breakpoint-between(md, lg){
    .AboutHome{
        .AboutHomeTextBox{
            width: 100%;
            margin-top: 0;
        }
    }
    .LinkArrow{
        a{
            font-size:14px;
        }
    }
}

@include media-breakpoint-between(lg, xxl){
    .AboutHome{
        .AboutHomeTextBox{
            width: 100%;
            margin-top: 0;
        }
    }
    .LinkArrow{
        a{
            font-size: 14px;
        }
    }
}

@include media-breakpoint-up(xxl){}