.DynamicImage{
    position: relative;
    img{
        width: 100%;
    }
}

.DynamicImageMargin{
    margin: 120px 0;
    @include media-breakpoint-down(md){
        margin:0 0 0 0;
    }
    @include media-breakpoint-between(md, lg){
        margin: 80px 0;
    }
    @include media-breakpoint-between(lg, xxl){
        margin: 80px 0;
    }
}

.business-set-up{
    .DynamicImageBox{
        .toggleCircle{
            .tooltipContent{
                h3{
                    display:none;
                }
            }
        }
    }
}

.DynamicImageBox{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include media-breakpoint-down(md){
        position: relative;
        margin-top: 20px;
    }
    .toggleCircle{
        position: absolute;
        border-radius: 100%;
        background: #fff;
        padding: 18px 18px;
        cursor: pointer;
        @include media-breakpoint-down(md){
            position: relative;
            top: inherit !important;
            left: inherit !important;
            bottom: inherit !important;
            right: inherit !important;
            transform: inherit !important;
            padding: 10px 25px 25px 25px;
        }
        @include media-breakpoint-down(md){
            &:nth-last-child(1){
                padding-bottom: 0;
            }
        }
        @include media-breakpoint-between(md, lg){
            padding:12px 12px;
        }
        &:after{
            position: absolute;
            content: "+";
            font-family: $fourthFont;
            font-size: 18px;
            color: $fontColor1;
            transition: 0.5s;
            transform: translate(-50%, -50%);
            @include media-breakpoint-down(md){
                content: "";
            }
        }
        &:hover{
            &:after{
                content: "-";
                transition: 0.5s;
            }
            .tooltipContent{
                visibility: visible;
                opacity: 1;
                transition: 0.5s;
            }
        }
        &:nth-child(1){
            top: 140px;
            left: 340px;
            @include media-breakpoint-between(md, lg){
                top: 50px;
                left: 30px;
            }
        }
        &:nth-child(2){
            top: 190px;
            left: 950px;
            transform: translate(-55%, 39%);
            @include media-breakpoint-between(md, lg){
                top: 170px;
                left: 40px;
            }
            @include media-breakpoint-between(lg, xxl){
                left: 800px;
            }
            .tooltipContent{
                @include media-breakpoint-between(lg, xl){
                    left: -310px;
                    top: -40px;
                }
            }
        }
        &:nth-child(3){
            top: 220px;
            right: 140px;
            .tooltipContent{
                left: -340px;
            }
            @include media-breakpoint-between(lg, xxl){
                top: 30px;
            }
        }
        &:nth-child(4){
            top: 400px;
            left: 370px;
            @include media-breakpoint-between(md, lg){
                top: 70px;
                left: 240px;
            }
        }
        &:nth-child(5){
            bottom: 140px;
            left: 145px;
            @include media-breakpoint-between(md, lg){
                left: 205px;
            }
            .tooltipContent{
                top: -110px;
            }
        }
        &:nth-child(6){
            top: 600px;
            left: 520px;
            @include media-breakpoint-between(md, lg){
                left: 300px;
                top: 30px;
            }
            @include media-breakpoint-between(lg, xxl){
                top: 50px;
            }
        }
        &:nth-child(7){
            top: 500px;
            left: 860px;
            @include media-breakpoint-between(md, lg){
                top: 120px;
                left: 400px;
            }
            @include media-breakpoint-between(lg, xxl){
                top: 350px;
                left: 660px;
            }
            .tooltipContent{
                @include media-breakpoint-between(lg, xl){
                    left: 30px;
                }
            }
        }
        &:nth-child(8){
            top: 500px;
            right: 300px;
            @include media-breakpoint-between(md, lg){
                top:250px;
            }
            .tooltipContent{
                left: -340px;
            }
        }
        .tooltipContent{
            position: absolute;
            width: 300px;
            background: #fff;
            border-radius: 5px;
            padding: 30px 30px;
            left:60px;
            top: 0;
            visibility: hidden;
            opacity: 0;
            transition: 0.5s;
            @include media-breakpoint-down(md){
                position: relative;
                width: 100%;
                padding: 15px;
                visibility: visible;
                opacity: 1;
                left: inherit !important;
                top: inherit !important;
                padding: 0;
            }
            @include media-breakpoint-between(md, lg){
                padding: 15px;
            }
            h3{
                font-family: $fourthFont;
                color: $secondaryColor;
                font-weight: 800;
                font-size: 16px;
                text-transform: capitalize;
                margin-bottom: 20px;
                @include media-breakpoint-down(md){
                    margin-bottom: 10px;
                }
            }
            p{
                margin-bottom: 0;
                font-family: $fourthFont;
                color: $fontColor1;
                font-size: 16px;
            }
        }
    }
}