.SliderGallery{
    .slick-slide{
        div{
            outline: none;
            margin: 0 20px 0 0;
            .imgMagnify{
                margin: 0;
            }
            .sliderImage{
                margin: 0;
                img{
                    width: 100%;
                }
            }
        }
    }
}

.SliderMargin{
    padding: 120px 0 0 0;
    @include media-breakpoint-down(md){
        padding: 60px 0 0 0;
    }
    @include media-breakpoint-between(md, xl){
        padding: 80px 0;
    }
}

.text2Col{
    @include media-breakpoint-down(md){
        margin-top: 30px;
    }
}

.two2ColFullWidth{
    .textModule2{
        .col-xl-5{
            width: 70%;
            text-align: center;
            @include media-breakpoint-down(md){
                width: 100%;
                text-align: left;
            }
        }
    }
}