.postContentTitle{
    font-family: $tertiaryFont;
    color: $fontColor1;
    font-size: 42px;
    margin-bottom: 40px;
    @include media-breakpoint-down(md){
        font-size: 32px;
    }
}

.singlePostBox img{
    width: 100%;
}

.postContent{
    font-family: $primaryFont;
    color: $fontColor1;
    font-size: 16px;
    line-height: 1.7;
    h2{
        margin-bottom: 25px;
        margin-top: 40px;
    }
    p{
        margin-bottom: 15px;
    }
}

.relatedBlogHeading{
    font-family: $tertiaryFont;
    color: $fontColor1;
    font-size: 50px;
    margin: 100px 0 80px 0;
    @include media-breakpoint-down(md){
        font-size:32px;
        margin:50px 0 40px 0;
    }
    @include media-breakpoint-between(md, xl){
        font-size:38px;
        margin:50px 0 40px 0;
    }
}