.BlogListing{
    padding: 140px 0 0 0;
    @include media-breakpoint-down(md) {
        padding: 120px 0 0 0;
    }
    @include media-breakpoint-between(md, xxl){
        padding: 120px 0 0 0;
    }
    .posts{
        .col-md-6{
            &:nth-child(1){
                .col-xl-12{
                    &:nth-child(even){
                        display: none;
                        @include media-breakpoint-down(md){
                            display: block;
                        }
                    }
                }
            }
            &:nth-child(2){
                .col-xl-12{
                    &:nth-child(odd){
                        display: none;
                    }
                }
            }
        }
    }
}

.postBox{
    .postTitle{
        font-family: $tertiaryFont;
        color: $fontColor1;
        font-size: 34px;
        text-decoration: none;
        text-transform: capitalize;
        margin-bottom: 40px;
        padding-right: 70px;
        line-height: 1.3;
        @include media-breakpoint-down(md){
            font-size: 24px;
            padding-right: 0;
        }
        @include media-breakpoint-between(md, xxl){
            font-size: 24px;
            padding-right: 40px;
        }
    }
    .postDate{
        font-family: $fourthFont;
        font-size: 15px;
        color: $fontColor1;
        opacity: 0.4;
        text-transform: uppercase;
        @include media-breakpoint-down(md){
            font-size: 13px;
        }
        @include media-breakpoint-between(md, xxl){
            font-size: 14px;
        }
    }
    .postThumb{
        img{
            width: 100%;
            @include media-breakpoint-down(md){
                width: 100%;
            }
            @include media-breakpoint-between(md, xxl){
                // width: 80%;
            }
        }
    }
}