.ThreeBlocksMargin{
    &.RemoveThreeBlocksMargin{
        .container{
            margin-bottom: 40px;
        }
    }
    .container {
        padding-bottom: 90px;
        margin-bottom: 90px;
        @include media-breakpoint-down(md){
            margin-bottom: 60px;
        }
        @include media-breakpoint-between(md, lg){
            margin-bottom: 80px;
            padding-bottom: 80px;
        }
        @include media-breakpoint-between(lg, xxl){
            margin-bottom: 80px;
            padding-bottom: 80px;
        }
    }
}

.ThreeBlocks{
    @include media-breakpoint-down(md){
        padding-top: 0;
    }
}

.SepLine{
    .container{
        position: relative;
        &:after{
            position: absolute;
            content: "";
            background: #dddddd;
            height: 1px;
            // width: 98.3%;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.ThreeBlocksList{
    .col-md-4{
        @include media-breakpoint-down(md) {
            &:nth-last-child(1){
                margin-bottom: 0 !important;
            }
        }
    }
}


.textModule5{
    h2{
        font-family: $tertiaryFont;
        font-size: 47px;
        color:$fontColor1;
        @include media-breakpoint-down(md){
            font-size: 37px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 37px;
        }
        @include media-breakpoint-between(lg, xxl){
            font-size: 42px;
        }
    }
    p{
        font-family: $fourthFont;
        font-size: 15px;
        color: $fontColor1;
        line-height: 1.8;
        @include media-breakpoint-down(md){
            font-size: 16px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 16px;
        }
    }
}

.textModule5Margin{
    h2{
        margin-bottom: 25px;
    }
}

.descThreeBlock{
    h3{
        font-family: $fourthFont;
        font-size: 23.31px;
        color: $fontColor1;
        font-weight: 800;
        @include media-breakpoint-down(md){
            font-size: 22px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 18px;
        }
        @include media-breakpoint-between(lg, xxl){
            font-size: 20px;
        }
    }
    p{
        font-family: $fourthFont;
        font-size: 16px;
        color: $fontColor1;
        line-height: 1.6;
    }
}

.pro-services{
    .descThreeBlockMargin{
        @include media-breakpoint-down(md){
            margin-top: 30px !important;
        }
    }
}

.descThreeBlockMargin{
    margin-top: 60px !important;
    @include media-breakpoint-down(md){
        margin-top: 60px !important;
    }
    @include media-breakpoint-between(md, lg){
        margin-top: 40px !important;
    }
    @include media-breakpoint-up(lg){
        margin-top: 60px;
    }
    h3{
        margin-bottom: 20px;
    }
}

.digit{
    background: $fontColor1;
    font-family: $tertiaryFont;
    position: absolute;
    padding: 5px 20px 15px 20px;
    line-height: 1;
    font-size: 54px;
    color: #fff;
    top: -75px;
    left: 50%;
    transform: translate(-50%, 0%);
    @include media-breakpoint-down(md){
        top: -100px;
    }
    @include media-breakpoint-between(md, lg){
        font-size: 35px;
    }
    @include media-breakpoint-up(lg){
        font-size: 54px;
        top: -105px;
    }
}

.ThreeBlocks .row .col-md-4:nth-last-child(1){
    .digit{
        padding: 0px 20px 20px 20px;
    }
}