.HomeVideo{
    position: relative;
    padding: 80px 0 140px 0;
    &:after{
        position: absolute;
        content: "";
        background: #141e2a;
        width: 100%;
        bottom: 0;
        height: 550px;
        z-index: -1;
    }
}


.textModule1{
    h2{
        font-family: $tertiaryFont;
        font-size: 38.50px;
        color: $fontColor1;
        img{
            position: relative;
            top: 10px;
            left: 10px;
        }
    }
}

.VideoThumb{
    .playIcon {
        width: 120px;
        height: 120px;
        background: radial-gradient( rgba(30, 104, 168, 0.73) 60%, rgba(30, 104, 168, 0.73) 69%);
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
            transform-origin: center center;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 25px solid #fff;
            z-index: 100;
            -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
            transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          }
          &:before {
            content: "";
            position: absolute;
            width: 150%;
            height: 150%;
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
            -webkit-animation: pulsate1 2s;
            animation: pulsate1 2s;
            -webkit-animation-direction: forwards;
            animation-direction: forwards;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: steps;
            animation-timing-function: steps;
            opacity: 1;
            border-radius: 50%;
            border: 5px solid rgba(255,255,255,0.3);
            top: -26%;
            left: -26%;
            background: rgb(30, 104, 168);
            transform: translate(-50%,-50%);
          }
      }
}
  
@keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px #fff, 0px 0px 25px 10px #fff;
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;

    }
}

.video_cta{
    h2{
        font-family: $fourthFont;
        font-size: 24px;
        color: #fff;
    }
    a {
        background: #2f76b4;
        font-family: $secondaryFont;
        color: #fff;
        display: inline-block;
        padding: 16px 60px;
        border-radius: 5px;
        text-decoration: none;
        &:hover{
            color: #f1f1f1;
            opacity: 0.8;
        }
    }
}

@include media-breakpoint-down(md){
    .HomeVideo{
        padding: 80px 0 60px 0;
        &:after{
            height: 370px;
        }
        .container{
            padding: 0 !important;
        }
        .VideoThumb{
            height: 360px;
            background-position: right -80px center !important;
            .playIcon{
                width: 80px;
                height: 80px;
            }
        }
        .textModule1{
            h2{
                margin: 0 20%;
                text-align: center;
                position: relative;
                &:after{
                    position: absolute;
                    content: "";
                    background:url(../../../media/arrowDown.jpg) no-repeat;
                    width: 41px;
                    height: 25px;
                    bottom: 0px;
                    right: -10px;
                }
            }
        }
    }
    .textModule1{
        h2{
            font-size: 24px;
            img{
                display: none;
            }
        }
    }
    .video_cta{
        h2{
            font-size: 18px;
            line-height: 1.4;
        }
        a{
            padding: 16px 50px;
        }
    }
}

@include media-breakpoint-between(md, lg){
    .HomeVideo{
        padding-bottom: 80px;
        &:after{
            height: 50%;
        }
    }
    .textModule1{
        h2{
            font-size: 32px;
        }
    }
    .video_cta{
        h2{
            font-size: 20px;
        }
        a{
            padding: 12px ​40px;
        }
    }
}

@include media-breakpoint-between(lg, xxl){}

@include media-breakpoint-up(xxl){}