.IntroForm{
    padding-top: 110px;
    @include media-breakpoint-down(md){
        padding-top: 30px;
    }
    .ContactIntro{
        font-family: $fourthFont;
        color: $fontColor1;
        font-size: 14px;
        @include media-breakpoint-up(lg){
            font-size: 15px;
        }
        p{
            margin-bottom: 30px;
        }
    }
    .ContactDetails{
        span{
            font-family: $fourthFont;
            color:$secondaryColor;
            font-size: 16px;
            font-weight: 600;
            @include media-breakpoint-up(xl){
                font-size: 17px;
            }
        }
        a{
            font-family: $fourthFont;
            color:$fontColor1;
            font-size: 24px;
            text-decoration: none;
            display: flex;
            align-items: center;
            line-height: 1;
            @include media-breakpoint-between(lg, xl){
                font-size: 28px;
            }
            @include media-breakpoint-up(xl){
                font-size: 37px;
            }
            img{
                width: 22px;
                opacity: 0.3;
                margin-right: 20px;
                position: relative;
            }
        }
        div{
            &:nth-child(1){
                img{
                    top: -2px;
                }
            }
            &:nth-child(2){
                margin-top: 50px;
                @include media-breakpoint-up(md){
                    margin-top: 30px;
                }
                img{
                    top: 2px;
                }
            }
        }
    }
    .SocialIconsContactBefore{
        position: relative;
        &:before{
            position: absolute;
            content: "";
            background: #e2e1e1;
            height: 1px;
            width: 100%;
            top: 0;
            left: 0;
        }
    }
}

.SocialIconsContact{
    a{
        display: inline-block;
        margin-right: 20px;
        svg{
            width: 18px;
            opacity: 0.3;
        }
        img{
            width: 18px;
            opacity: 0.3;
        }
    }
}

.customForm{
    padding: 30px 30px;
    border:1px solid #f5f5f5;
    box-shadow: 10px 10px 22px rgba(0,0,0,0.05);
    background: #fff;
    @include media-breakpoint-up(lg){
        padding:60px 60px;
    }
    &.customFormMargin{
        margin-top: 0;
        @include media-breakpoint-down(md){
            margin-top: 40px;
        }
        @include media-breakpoint-up(md){
            margin-top: -220px;
        }
    }
    h3{
        text-align:center;
        font-family: $fourthFont;
        font-size: 28px;
        color: $fontColor1;
        text-transform: capitalize;
        @include media-breakpoint-between(lg, xl){
            font-size: 32px;
        }
        @include media-breakpoint-up(xl){
            font-size: 38px;
        }
        @include media-breakpoint-between(xl, xxl){
            font-size: 32px;
        }
    }
    p{
        text-align:center;
        font-family: $primaryFont;
        font-size: 14px;
        margin-bottom: 30px;
        @include media-breakpoint-up(xl){
            font-size: 16px;
        }
    }
    label{
        width:100%;
        color:rgba(0,0,0,0.5);
        font-family:$secondaryFont;
        font-size:14px;
        margin-bottom:15px;
        padding-top:7px;
        @include media-breakpoint-up(lg){
            font-size: 16px
        }
        input{
            box-shadow:none !important;
            border:1px solid #eff1f8;
            background:#f9f9fc;
            margin-top:5px;
            border-radius:5px;
            padding: 10px 10px;
            font-family: $primaryFont;
            &:hover{
                border:1px solid #000;
            }
            &:focus{
                border:1px solid #000;
            }
        }
        select{
            box-shadow:none !important;
            border:1px solid #eff1f8;
            background:#f9f9fc url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5%206l5%205%205-5%202%201-7%207-7-7%202-1z%22%20fill%3D%22%23555%22%2F%3E%3C%2Fsvg%3E') no-repeat right 10px top 55%;
            margin-top:5px;
            border-radius:5px;
            padding: 10px 10px;
            font-family: $primaryFont;
            &:hover{
                border:1px solid #000;
            }
            &:focus{
                border:1px solid #000;
            }
        }
        textarea{
            box-shadow:none !important;
            border:1px solid #eff1f8;
            background:#f9f9fc;
            margin-top:5px;
            border-radius:5px;
            height: 100px;
            padding: 10px 10px;
            font-family: $primaryFont;
            &:hover{
                border:1px solid #000;
            }
            &:focus{
                border:1px solid #000;
            }
        }
        input[type="submit"]{
            background:$secondaryColor;
            font-family: $fourthFont;
            font-size: 19px;
            color: #fff;
            padding: 20px 10px;
            width: 100%;
        }
    }
}

.react-tel-input{
    padding: 0;
    margin: 0;
    border: 0;
    input{
        &.form-control{
            box-shadow: none !important;
            border-radius: 10px 0 0 5px;
            background: #f9f9fc;
            margin-top: 5px !important;
            border-radius: 5px;
            padding: 22px 0px 22px 50px;
            font-family: "asl";
            width: 100%;
            border-left: 1px solid #eff1f8;
            border-bottom: 1px solid #eff1f8;
            border-top: 1px solid #eff1f8;
            border-right: 0;
            cursor: not-allowed;
            &:hover, &:focus{
                border: none;
            }
        }
    }
    .flag-dropdown{
        border: 1px solid #eff1f8 !important;
        border-right: none !important;
        background: #f9f9fc !important;
    }
}