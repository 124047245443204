.SimpleHeader{
    &.SimpleHeaderImage{
        min-height: 580px;
        @include media-breakpoint-down(md){
            min-height: 350px;
            background-position: center !important;
        }
        @include media-breakpoint-up(md){
            min-height: 450px;
            background-position: center !important;
        }
        h1{
            font-size: 86px;
            @include media-breakpoint-down(md){
                font-size: 56px;
            }
        }
    }
    background: #f3f3f3;
    min-height: 320px;
    @include media-breakpoint-down(md){
        min-height: 250px;
    }
    @include media-breakpoint-between(md, xl){
        min-height: 250px;
    }
    h1{
        margin-bottom: 0;
        font-family: $tertiaryFont;
        font-size: 58px;
        color: $fontColor1;
        @include media-breakpoint-down(md){
            font-size: 38px;
        }
        @include media-breakpoint-between(md, xl){
            font-size: 48px;
        }
    }
    .SimpleHeaderTitle{
        min-height: inherit;
        .container{
            min-height: inherit;
            .row{
                min-height: inherit;
            }
        }
    }
}

.Contact{
    .SimpleHeader{
        h1{
            color: #fff;
        }
    }
}

.SimpleHeaderAfter{
    position: relative;
    &:after{
        position: absolute;
        content: "";
        background: #f3f3f3;
        height: 100px;
        width: 50%;
        bottom: -100px;
        right: 0;
    }
}