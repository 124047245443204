.Footer{
    background: #141e2a;
    padding: 120px 0;
    a{
        text-decoration: none;
        border-bottom: 1px solid transparent;
        &:hover{
            border-bottom: 1px solid rgba(255,255,255,0.5);
            color: #f1f1f1;
        }
    }
    .footerTitle{
        font-family: $primaryFont;
        color: rgba(255,255,255,0.71);
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
        margin-bottom: 5px;
    }
    .copyrightText{
        a{
            color: rgba(255, 255, 255, 0.71);
            &:hover{
                color: #fff;
            }
        }
    }
    .servicesLinks{
        li{
            a{
                font-family: $tertiaryFont;
                color: #fff;
                font-size: 29px;
            }
        }
    }
    .footerlinks{
        a{
            font-family: $primaryFont;
            color: rgba(255,255,255,0.8);
            font-size: 14px;
            display: inline-block;
            text-transform: capitalize;
        }
    }
    .Linksep{
        display: block;
        a{
            position: relative;
            padding-right: 5px;
            margin-right: 5px;
            &:after{
                display: none;
                position: absolute;
                content: "";
                background: rgba(255,255,255,0.8);
                height: 100%;
                width: 1px;
                right: 0;
            }
            &:hover{
                border-bottom: 1px solid transparent;
            }
        }
        span{
            &:nth-last-child(1){
                a{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    .pageslinks{
        a{
            font-family: $primaryFont;
            display: inline-block;
            text-transform: uppercase;
            color: #fff;
        }
    }
    P{
        color: rgba(255,255,255,0.71);
        font-size: 14px;
        font-family: $primaryFont;
    }
    .footerLogo{
        &:hover{
            border-bottom: 1px solid transparent !important;
        }
        img{
            width: 100%;
            filter: brightness(100);
        }
    }
}

.socialIcons{
    li{
        margin: 0 7px;
        a{
            &:hover{
                border-color: transparent;
            }
            svg{
                width: 14px;
                height: 14px;
                path{
                    fill: #fff !important;
                    opacity: 0.5;
                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .footerLogo{
        img{
            width: 100%;
        }
    }
    .Footer{
        padding: 60px 0;
        .servicesLinks{
            li{
                a{
                    font-size: 22px;
                }
            }
        }
        .footerTitle{
            font-size: 12px;
            opacity: 0.5;
        }
        .pageslinks{
            a{
                font-size: 14px;
                margin: 0 15px;
                opacity: 0.5;
                &:nth-child(1){
                    margin-left: 0;
                }
            }
        }
        .footerlinks{
            a{
                opacity: 0.5;
                padding-right: 0;
                margin-right: 0;
                display: block;
            }
        }
        .Linksep{
            display: block;
            a{
                &:after{
                    display: none;
                }
            }
        }
        .footerTitleMarginMobile{
            margin-bottom: 15px;
        }
    }
}

@include media-breakpoint-between(md, lg){
    .Footer{
        padding: 80px 0;
        .servicesLinks{
            li{
                a{
                    font-size: 24px;
                }
            }
        }
        .Linksep{
            a{
                margin-right: 10px;
                padding-right: 10px;
            }
        }
        .pageslinks{
            a{
                font-style: 14px;
            }
        }
    }
    
}

.Campaign{
    .modal-dialog{
        max-width: 500px !important;
    }
    .close{
        top: 15px;
        right: 25px;
        path{
            fill: #000 !important;
        }
    }
    .row{
        height: 650px;
    }
    .popupText{
        padding: 30px 30px;
        a{
            background:$secondaryColor;
            font-family: $fourthFont;
            font-size: 14px;
            color: #fff;
            padding: 10px 20px;
            display: inline-block;
            margin-top: 20px;
        }
        .you-t {
            color: #2e2d2d;
            text-transform: uppercase;
            line-height: 45px;
            font-size: 38px;
            font-weight: bold;
            margin:22px 0px 27px 0px;
        }
        .fif-off {
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: 'Montserrat', sans-serif;
            span {
                font-size: 54px;
                color: #d70b0b;
                font-weight: 900;
            }
        }
        ul.green-ticked-ul {
            list-style: none;
            margin-left: auto;
            margin-bottom: 20px;
            margin-top: 8px;
            padding: 0px;
            li {
                background-image: url('https://www.myoffice.ae/img/tick-myoff.png');
                background-repeat: no-repeat;
                background-position: left 4px;
                padding-left: 26px;
                background-size: 15px;
                color: #484848;
                line-height: 21px;
                margin-bottom: 7px;
            }
        }
    }
}

.customForm{
    &.popupForm{
        border:none;
        box-shadow: none;
        padding: 60px 20px 20px 20px;
        p{
            text-align: left;
        }
    }
}

.SecondsPopup{
    .modal-dialog{
        max-width: 700px;
    }
    a{
        display: block;
        &:hover{
            text-decoration: none;
            border: none;
        }
        img{
            width: 100%;
        }
    }
    .modal-content{
        background: transparent;
        border:none;
    }
    .close{
        top: 12px;
        right: 22px;
        svg{
            width: 14px;
            height: 14px;
        }
    }
}