.Team{
    background: #f5f5f5;
}

.teamBox{
    @include media-breakpoint-down(md) {
        margin-right: 20px;
    }
    @include media-breakpoint-between(md, lg){
        margin-right: 30px;
    }
    .teamBoxContent{
        font-family: $fourthFont;
        h3{
            font-size: 20px;
            color: $fontColor1;
            text-transform: uppercase;
            @include media-breakpoint-down(md){
                font-size: 16px;
            }
            @include media-breakpoint-between(lg, xxl){
                font-size: 18px;
            }
        }
        p{
            color: #8d8d8d;
            font-size: 15px;
        }
    }
}

.TeamMargin{
    padding: 100px 0;
    @include media-breakpoint-down(md){
        padding: 60px 0;
    }
    @include media-breakpoint-between(md, lg){
        padding: 80px 0;
    }
}