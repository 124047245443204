.subHeader{
    .heading_h1_48px{
        h1{
            font-size: 48px !important;
        }
    }
    .fw400_p{
        p{
            font-weight: 400 !important;
        }
    }
    &.IncreaseHeightTablet{
        .SubHeaderRight{
            @include media-breakpoint-between(md, lg) {
            height: 74vh;
            }
        }
    }
    .container{
        position: absolute;
        left: 0;
        right: 0;
        @include media-breakpoint-down(md){
            position: relative;
        }
    }
    .SubHeaderRight{
        @include media-breakpoint-between(md, lg){
            height: 58vh;
        }
        @include media-breakpoint-up(lg){
            min-height: 50vh;
        }
        @include media-breakpoint-up(xl){
            min-height: 64vh;
        }
    }
    .subHeaderText{
        @include media-breakpoint-down(md){
            padding: 40px 0 40px 0;
        }
        @include media-breakpoint-between(md, lg){
            padding: 30px 20px;
        }
        .btnTagline{
            font-size: 11px;
            color: rgba(0,0,0,0.7);
            font-family: 'pnr';
            margin-top: 5px;
            display: block;
        }
        h1{
            font-family: $tertiaryFont;
            color: $fontColor1;
            font-size: 74px;
            margin-bottom: 0;
            @include media-breakpoint-down(md){
                font-size: 42px;
            }
            @include media-breakpoint-between(md, lg){
                font-size: 38px;
            }
            @include media-breakpoint-between(lg, xxl){
                font-size: 54px;
            }
            i{
                color: #2f76b4;
                font-style: normal;
                display: inline;
            }
        }
        p{
            font-family: $fourthFont;
            color: $fontColor1;
            font-size: 18px;
            font-weight: 800;
            margin: 30px 0 30px 0;
            line-height: 1.6;
            @include media-breakpoint-down(md){
                font-size: 16px;
                margin: 10px 0 20px 0;
            }
            @include media-breakpoint-between(md, lg){
                margin: 15px 0;
                font-size: 16px;
            }
            @include media-breakpoint-between(lg, xxl){
                font-size: 16px;
            }
            a{
                color: #303951;
                text-decoration: none;
                &:hover{
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
        ul{
            padding-left: 20px;
            margin-bottom: 40px;
            font-family: "pnr";
            color: #303951;
            font-size: 19px;
            font-weight: 800;
            @include media-breakpoint-down(md){
                font-size: 16px;
            }
        }
        address{
            font-family: $fourthFont;
            font-weight: 800;
            color: $secondaryColor;
            font-size: 20px;
            margin: 40px 0;
            position: relative;
            padding-left: 35px;
            @include media-breakpoint-down(md){
                font-size: 16px;
                margin: 20px 0;
            }
            @include media-breakpoint-between(md, xl){
                font-size: 16px;
                br{
                    display: none;
                }
            }
            &:before{
                position: absolute;
                content: "";
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxwYXRoIGQ9Ik0yNTYsMEMxNTYuNzQ4LDAsNzYsODAuNzQ4LDc2LDE4MGMwLDMzLjUzNCw5LjI4OSw2Ni4yNiwyNi44NjksOTQuNjUybDE0Mi44ODUsMjMwLjI1Nw0KCQkJYzIuNzM3LDQuNDExLDcuNTU5LDcuMDkxLDEyLjc0NSw3LjA5MWMwLjA0LDAsMC4wNzksMCwwLjExOSwwYzUuMjMxLTAuMDQxLDEwLjA2My0yLjgwNCwxMi43NS03LjI5Mkw0MTAuNjExLDI3Mi4yMg0KCQkJQzQyNy4yMjEsMjQ0LjQyOCw0MzYsMjEyLjUzOSw0MzYsMTgwQzQzNiw4MC43NDgsMzU1LjI1MiwwLDI1NiwweiBNMzg0Ljg2NiwyNTYuODE4TDI1OC4yNzIsNDY4LjE4NmwtMTI5LjkwNS0yMDkuMzQNCgkJCUMxMTMuNzM0LDIzNS4yMTQsMTA1LjgsMjA3Ljk1LDEwNS44LDE4MGMwLTgyLjcxLDY3LjQ5LTE1MC4yLDE1MC4yLTE1MC4yUzQwNi4xLDk3LjI5LDQwNi4xLDE4MA0KCQkJQzQwNi4xLDIwNy4xMjEsMzk4LjY4OSwyMzMuNjg4LDM4NC44NjYsMjU2LjgxOHoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCgk8Zz4NCgkJPHBhdGggZD0iTTI1Niw5MGMtNDkuNjI2LDAtOTAsNDAuMzc0LTkwLDkwYzAsNDkuMzA5LDM5LjcxNyw5MCw5MCw5MGM1MC45MDMsMCw5MC00MS4yMzMsOTAtOTBDMzQ2LDEzMC4zNzQsMzA1LjYyNiw5MCwyNTYsOTB6DQoJCQkgTTI1NiwyNDAuMmMtMzMuMjU3LDAtNjAuMi0yNy4wMzMtNjAuMi02MC4yYzAtMzMuMDg0LDI3LjExNi02MC4yLDYwLjItNjAuMnM2MC4xLDI3LjExNiw2MC4xLDYwLjINCgkJCUMzMTYuMSwyMTIuNjgzLDI4OS43ODQsMjQwLjIsMjU2LDI0MC4yeiIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K);
                width: 23px;
                height: 23px;
                top: 3px;
                left: 0;
                opacity: 0.3;
            }
        }
    }
    &.heading48px{
        h1{
            font-size: 40px;
            @include media-breakpoint-down(md){
                font-size: 40px;
            }
            @include media-breakpoint-between(md, lg){
                font-size: 36px;
            }
            @include media-breakpoint-between(lg, xxl){
                font-size: 48px;
            }
        }
    }
    .subHeaderImage{
        position: relative;
        right: 80px;
        top: 0;
        width: 40%;
        @include media-breakpoint-down(md){
            width: 100%;
            right: 0;
            left: 0;
            top: 0;
            position: relative;
        }
        @include media-breakpoint-between(md, lg){
            // width: 100%;
            right: 60px;
            // left: 0;
            // z-index: -1;
        }
        img{
            width: 100%;
        }
    }
}

.RegularParagraph{
    p{
        font-weight: normal !important;
    }
}

.btn1{
    background: #2fb45e;
    font-family: $fourthFont;
    color: #fff !important;
    font-size: 18px;
    font-weight: 800;
    display: inline-block;
    border-radius: 5px;
    padding: 18px 50px;
    text-decoration: none;
    transition:0.5s;
    @include media-breakpoint-down(md){
        font-size: 16px;
        padding: 16px 40px;
    }
    @include media-breakpoint-between(md, lg){
        font-size: 16px;
        padding: 14px 30px;
    }
    @include media-breakpoint-between(lg, xxl){
        font-size: 16px;
        padding: 14px 30px;
    }
    &:hover{
        background: #2f76b4;
        transition:0.5s;
        color: #f1f1f1;
        opacity: 0.9;
    }
}

.custom-slickDots{
    .slick-dots{
        bottom: 50px;
        @include media-breakpoint-between(md, lg){
            bottom: 30px;
        }
        li{
            button{
                &:before{
                    opacity: 0.5;
                    font-size: 11px;
                    color: #fff;
                    @include media-breakpoint-between(md, lg){
                        font-size: 8px;
                    }
                }
            }
        }
        li.slick-active{
            button{
                &:before{
                    opacity: 1;
                    color: #fff;
                }
            }
        }
    }
}

.DubaiDowntown{
    .subHeaderText{
        h1{
            @include media-breakpoint-down(sm){
                font-size: 40px;
            }
        }
    }
}

.co-working-space-dubai{
    .subHeader{
        .row{
            justify-content: flex-end;
        }
        .SubHeaderRight{
            .d-flex{
                justify-content: flex-start!important;
                .subHeaderImage{
                    left: 0;
                    right: inherit;
                    @include media-breakpoint-up(md){
                        left: 110px;
                    }
                    @include media-breakpoint-between(md, lg){
                        left: 30px;
                    }
                    @include media-breakpoint-between(lg, xl){
                        left:45px;
                    }
                    @include media-breakpoint-between(xl, xxl){
                        left: 50px;
                    }
                }
            }
        }
    }
}

.corporate-event-spaces-dubai{
    .subHeader{
        .row{
            justify-content: flex-end;
        }
        .SubHeaderRight{
            .d-flex{
                justify-content: flex-start!important;
                .subHeaderImage{
                    left: 0;
                    right: inherit;
                    @include media-breakpoint-up(md){
                        left: 110px;
                    }
                    @include media-breakpoint-between(md, lg){
                        left: 30px;
                    }
                    @include media-breakpoint-between(lg, xl){
                        left:45px;
                    }
                    @include media-breakpoint-between(xl, xxl){
                        left: 50px;
                    }
                }
            }
        }
    }
}