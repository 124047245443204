.OfferPoints{
    background: #fffefe;
    position: relative;
    .row{
        .col-xl-3{
            margin-bottom: 35px;
            &:nth-last-child(1){
                margin-bottom: 0 !important;
            }
            &:nth-child(3){
                img{
                    opacity: 0.8;
                }
            }
            &:nth-child(4){
                img{
                    opacity: 0.7;
                }
            }
        }
    }
}

.TabKeypoints{
    &.AboutKeypoints{
        padding: 90px 0 !important;
        @include media-breakpoint-down(md){
            padding: 40px 0 !important;
        }
        p{
            @include media-breakpoint-down(xl){
                font-size: 16px !important;
            }
        }
        #nav-1{
            .row{
                .col-xl-3{
                    width: 100%;
                    margin-bottom: 10px !important;
                    p{
                        padding-left: 30px;
                        margin-bottom: 0;
                        svg{
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                        }
                        &:before {
                            position: absolute;
                            content: "";
                            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTExLjk5OSA1MTEuOTk5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBhdGggZD0iTTUwNi4yMzEsNzUuNTA4Yy03LjY4OS03LjY5LTIwLjE1OC03LjY5LTI3Ljg0OSwwbC0zMTkuMjEsMzE5LjIxMUwzMy42MTcsMjY5LjE2M2MtNy42ODktNy42OTEtMjAuMTU4LTcuNjkxLTI3Ljg0OSwwDQoJCQljLTcuNjksNy42OS03LjY5LDIwLjE1OCwwLDI3Ljg0OWwxMzkuNDgxLDEzOS40ODFjNy42ODcsNy42ODcsMjAuMTYsNy42ODksMjcuODQ5LDBsMzMzLjEzMy0zMzMuMTM2DQoJCQlDNTEzLjkyMSw5NS42NjYsNTEzLjkyMSw4My4xOTgsNTA2LjIzMSw3NS41MDh6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
                            background-size: 100%;
                            width: 18px;
                            height: 18px;
                            top: 9px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
    .row{
        .col-xl-3{
            img{
                opacity: 0.7;
            }
            &:nth-child(4){
                img{
                    opacity: 0.4;
                }
            }
        }
    }
    
}

.AboutKeypoints{
    padding: 20px 0 80px 0 !important;
    @include media-breakpoint-down(md){
        padding: 20px 0 60px 0 !important;
    }
    &:before{
        display: none;
    }
    img{
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
        @include media-breakpoint-between(md, lg){
            margin-bottom: 0;
        }
    }
    p{
        font-family: $fourthFont !important;
        font-size: 24px !important;
        color: $secondaryColor !important;
        line-height: 1.5 !important;
        @include media-breakpoint-down(md) {
            font-size: 18px !important;
        }
        @include media-breakpoint-between(md, lg) {
            font-size: 20px !important;
        }
        @include media-breakpoint-between(lg, xxl){
            font-size: 22px !important;
        }
    }
    .row{
        .col-xl-3{
            margin-bottom: 20px !important;
        }
    }
}

.OfferPointsMargin{
    padding: 220px 0 60px 0;
}

.OfferPointsBefore:before{
    position: absolute;
    content: "";
    background: #faf9f9;
    height: 150px;
    width: 50%;
    top: 0;
    right: 0;
}

.textModule3{
    h3 {
        font-family: $primaryFont;
        text-transform: uppercase;
        color: rgba(63, 63, 63, 0.71);
        font-size: 17px;
        letter-spacing: 2px;
        margin-bottom: 60px;
        @include media-breakpoint-between(md, lg){
            font-size: 14px;
        }
        @include media-breakpoint-between(lg, xxl){
            font-size: 16px;
        }
    }
}

.OfferPointsBox{
    a {
        font-family: $tertiaryFont;
        font-size: 36px;
        color: #2f76b4;
        margin-bottom: 25px;
        display: block;
        text-decoration: none;
        &:hover{
            color:#000;
        }
    }
    h3{display: none;}
    p {
        font-family: $primaryFont;
        color: $fontColor1;
        font-size: 16px;
        line-height: 1.8;
    }
}

@include media-breakpoint-down(md){
    .OfferPointsBefore{
        &:before{
            height: 60px;
            width: 50%;
            display: none;
        }
    }
    .OfferPointsMargin{
        padding: 50px 0 50px 0;
    }
    .textModule3{
        h3{
            margin-bottom: 40px;
            font-size: 16px;
        }
    }
    .OfferPointsBox{
        a{
            font-size: 24px;
            margin-bottom: 15px;
        }
        p{
            line-height: 1.5;
            br{
                display: none;
            }
        }
    }
    .OfferPoints{
        .row{
            .col-xl-3{
                .OfferPointsBox{
                    div{
                        p{
                            &:nth-last-child(1){
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                &:nth-last-child(1){
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include media-breakpoint-between(md, lg){
    .OfferPointsBefore{
        &:before{
            height: 100px;
        }
    }
    .OfferPointsBox{
        a{
            font-size: 32px;
        }
    }
    .OfferPointsMargin{
        padding: 140px 0 60px 0;
    }
    .OfferPoints{
        .row{
            &:nth-last-child(1){
                margin-bottom: 0;
            }
        }
    }
}

@include media-breakpoint-between(lg, xxl){
    .OfferPointsMargin{
        padding: 140px 0 60px 0;
    }
    .OfferPointsBox{
        a{
            font-size: 32px;
        }
    }
}

@include media-breakpoint-up(xxl){}