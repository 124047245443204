.CTA{
    background: #f3f3f3;
    padding:100px 0;
    .CTA__content{
        h4{
            font-family: $fourthFont;
            color:#a9a9a9;
            font-size: 17px;
            letter-spacing: 2.4px;
            text-transform: uppercase;
        }
        h2{
            font-family: $tertiaryFont;
            color:$fontColor1;
            font-size: 34px;
            @include media-breakpoint-between(lg, xl){
                font-size: 44px;
            }
            @include media-breakpoint-up(xl){
                font-size: 59px;
            }
        }
        a{
            display: inline-block;
            background: $secondaryColor;
            font-family: $fourthFont;
            font-size: 16px;
            text-transform: capitalize;
            padding: 16px 40px;
            border-radius: 5px;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
            &:hover{
                opacity: 0.9;
            }
            @include media-breakpoint-up(lg){
                padding: 16px 40px;
                font-size: 19px;
            }
        }
        &.CTA__contentMargin{
            a{
                margin-top: 25px;
            }
        }
    }
}