.FAQTopics{
    padding: 180px 0 60px 0;
    @include media-breakpoint-down(md){
        padding: 140px 0 20px 0;
    }
    .nav-tabs{
        border:0px;
        li{
            text-align: left;
            border:0px solid transparent;
            a{
                font-family: $fourthFont;
                font-size: 17px;
                color: $fontColor1;
                text-decoration: none;
                cursor: pointer;
                @include media-breakpoint-down(xxl){
                    font-size: 16px;
                }
                &:hover{
                    text-decoration: underline;
                    color: #000;
                }
            }
        }
    }
    ul{
        li{
            a{
                font-family: $fourthFont;
                font-size: 17px;
                color: $fontColor1;
                text-decoration: none;
                @include media-breakpoint-down(xxl){
                    font-size: 16px;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

.accordion{
    .accordion-item{
        margin-bottom: 20px;
        border: 1px solid #e3e6e9;
        border-radius: 5px;
        box-shadow: none;
        .accordion-header{
            outline: none;
            button{
                background: transparent;
                box-shadow: none;
                border:none;
                font-family: $fourthFont;
                font-size: 18px;
                color: #555555;
                @include media-breakpoint-down(xxl){
                    font-size: 16px;
                }
            }
        }
    }
    .accordion-body{
        font-family: $fourthFont;
        font-size: 16px;
        color: $fontColor1;
        @include media-breakpoint-down(xxl){
            font-size: 16px;
        }
    }
}