

.HomeTest{
    position: relative;
    padding:140px 0;
    @include media-breakpoint-between(md, lg){
        padding: 80px 0;
    }
    &:after{
        position: absolute;
        content: "";
        background: #f7f7f7;
        width: 65%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        @include media-breakpoint-between(md, lg){
            width: 54%;
        }
    }
    .testBox{
        .imgbg{
            // background: #f7f7f7;
            img{
                mix-blend-mode: darken;
                width: 60%;
            }
        }
        p {
            font-family: $tertiaryFont;
            font-size: 26px;
            color: #110b09;
            @include media-breakpoint-between(md, lg){
                font-size: 12px;
            }
        }
        span{
            color: rgba(48, 57, 81, 0.8);
            text-transform: uppercase;
            letter-spacing: 0.6px;
            margin-right: 10px;
            font-size: 10px;
            padding-right: 10px;
            position: relative;
            @include media-breakpoint-down(md){
                font-size: 10px;
            }
            @include media-breakpoint-down(md){
                margin-right: 0;
                display: block;
            }
            @include media-breakpoint-up(lg){
                font-size: 12px;
                padding-right: 30px;
            }
            @include media-breakpoint-up(xl){
                font-size: 12px;
                padding-right: 25px;
            }
            @include media-breakpoint-up(xxl){
                padding-right: 35px;
                font-size: 12px;
            }
            &:after{
                position: absolute;
                content: "";
                background: #dddcdb;
                height: 140%;
                width: 2px;
                right: 0;
                top: -2px;
            }
            @include media-breakpoint-up(xs){
                // padding-right: 25px;
                // font-size: 14px;
            }
            @include media-breakpoint-between(md, lg){
            }
            .row{
                // background: #f7f7f7;
                @include media-breakpoint-up(md){
                    mix-blend-mode: unset;
                }
                img{
                    mix-blend-mode: darken;
                    width: 100%;
                    @include media-breakpoint-between(md, lg){
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .HomeTest{
        padding: 60px 0;
        &:after{
            width: 58%;
        }
        .testBox{
            p{
                font-size: 22px;
            }
            // span{
                // font-size: 13px;
            // }
            .row{
                // background: #f7f7f7;
                img{
                    // mix-blend-mode: darken;
                    width: 100%;
                }
            }
        }
    }
}