.ContactInfo{
    h4{
        font-family:$fourthFont;
        color: $secondaryColor;
        text-transform: uppercase;
        font-size: 16px;
        @include media-breakpoint-up(lg){
            font-size: 20px;
        }
    }
    ul{
        list-style: none;
        font-family: $fourthFont;
        color: $fontColor1;
        font-size: 16px;
        margin-bottom: 0;
        @include media-breakpoint-up(lg){
            font-size: 18px;
        }
        li{
            margin-bottom: 15px;
            @include media-breakpoint-up(lg){
                margin-bottom: 25px;
            }
            &:before{
                position: absolute;
                width: 16px;
                height: 16px;
                top: 7px;
                left: -30px;
                opacity: 0.3;
            }
        }
    }
}
.ContactInfoIcon{
    position: relative;
    &.locationIcon{
        &:before{
            content: "";
            background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNMjU2LDBDMTYxLjg5NiwwLDg1LjMzMyw3Ni41NjMsODUuMzMzLDE3MC42NjdjMCwyOC4yNSw3LjA2Myw1Ni4yNiwyMC40OSw4MS4xMDRMMjQ2LjY2Nyw1MDYuNQ0KCQkJYzEuODc1LDMuMzk2LDUuNDQ4LDUuNSw5LjMzMyw1LjVzNy40NTgtMi4xMDQsOS4zMzMtNS41bDE0MC44OTYtMjU0LjgxM2MxMy4zNzUtMjQuNzYsMjAuNDM4LTUyLjc3MSwyMC40MzgtODEuMDIxDQoJCQlDNDI2LjY2Nyw3Ni41NjMsMzUwLjEwNCwwLDI1NiwweiBNMjU2LDI1NmMtNDcuMDUyLDAtODUuMzMzLTM4LjI4MS04NS4zMzMtODUuMzMzYzAtNDcuMDUyLDM4LjI4MS04NS4zMzMsODUuMzMzLTg1LjMzMw0KCQkJczg1LjMzMywzOC4yODEsODUuMzMzLDg1LjMzM0MzNDEuMzMzLDIxNy43MTksMzAzLjA1MiwyNTYsMjU2LDI1NnoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==");
        }
    }
    &.telephoneIcon{
        &:before{
            content: "";
            background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDA1LjMzMyA0MDUuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MDUuMzMzIDQwNS4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNMzczLjMzMywyNjYuODhjLTI1LjAwMywwLTQ5LjQ5My0zLjkwNC03Mi43MDQtMTEuNTYzYy0xMS4zMjgtMy45MDQtMjQuMTkyLTAuODk2LTMxLjYzNyw2LjY5OWwtNDYuMDE2LDM0Ljc1Mg0KCQkJYy01Mi44LTI4LjE4MS04Ni41OTItNjEuOTUyLTExNC4zODktMTE0LjM2OGwzMy44MTMtNDQuOTI4YzguNTEyLTguNTEyLDExLjU2My0yMC45NzEsNy45MTUtMzIuNjQNCgkJCUMxNDIuNTkyLDgxLjQ3MiwxMzguNjY3LDU2Ljk2LDEzOC42NjcsMzJjMC0xNy42NDMtMTQuMzU3LTMyLTMyLTMySDMyQzE0LjM1NywwLDAsMTQuMzU3LDAsMzINCgkJCWMwLDIwNS44NDUsMTY3LjQ4OCwzNzMuMzMzLDM3My4zMzMsMzczLjMzM2MxNy42NDMsMCwzMi0xNC4zNTcsMzItMzJWMjk4Ljg4QzQwNS4zMzMsMjgxLjIzNywzOTAuOTc2LDI2Ni44OCwzNzMuMzMzLDI2Ni44OHoiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==");
        }
    }
    &.timeIcon{
        &:before{
            content: "";
            background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0NDMuMjk0IDQ0My4yOTQiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNDQzLjI5NCA0NDMuMjk0IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0yMjEuNjQ3IDBjLTEyMi4yMTQgMC0yMjEuNjQ3IDk5LjQzMy0yMjEuNjQ3IDIyMS42NDdzOTkuNDMzIDIyMS42NDcgMjIxLjY0NyAyMjEuNjQ3IDIyMS42NDctOTkuNDMzIDIyMS42NDctMjIxLjY0Ny05OS40MzMtMjIxLjY0Ny0yMjEuNjQ3LTIyMS42NDd6bTAgNDE1LjU4OGMtMTA2Ljk0MSAwLTE5My45NDEtODctMTkzLjk0MS0xOTMuOTQxczg3LTE5My45NDEgMTkzLjk0MS0xOTMuOTQxIDE5My45NDEgODcgMTkzLjk0MSAxOTMuOTQxLTg3IDE5My45NDEtMTkzLjk0MSAxOTMuOTQxeiIvPjxwYXRoIGQ9Im0yMzUuNSA4My4xMThoLTI3LjcwNnYxNDQuMjY1bDg3LjE3NiA4Ny4xNzYgMTkuNTg5LTE5LjU4OS03OS4wNTktNzkuMDU5eiIvPjwvc3ZnPg==");
        }
    }
}