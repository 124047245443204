.textLeft_content{
    h2{
        font-family: $tertiaryFont;
        color: $fontColor1;
        font-size: 79px;
        @include media-breakpoint-down(md){
            font-size: 39px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 39px;
        }
        @include media-breakpoint-between(lg, xxl){
            font-size:49px;
        }
        i{
            color: #2f76b4;
            font-style: normal;
            display: block;
        }
    }
    h3{
        font-family: $primaryFont;
        color: #3f3f3f;
        opacity: 0.7;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 2px;
        @include media-breakpoint-down(md){
            font-size:14px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 14px;
        }
    }
    p{
        font-family: $fourthFont;
        font-size: 17px;
        color: $fontColor1;
        line-height: 1.6;
        margin-bottom: 30px;
        @include media-breakpoint-down(md){
            font-size:16px;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 16px;
        }
        // &:nth-last-child(1){
        //     margin-bottom: 0;
        // }
    }
    em{
        font-family: $fourthFont;
        color: #2f76b4;
        display: block;
        font-style: normal;
        font-weight: 800;
    }
    a{
        font-family: $fourthFont;
        color: $fontColor1;
        text-decoration: none;
        color: #2560A5;
        text-transform: uppercase;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 138.889% */
        text-decoration-line: underline;
        &:hover{
            color: #000;
            text-decoration: none;
            border-bottom: 1px solid #c9c9ce;
        }
        &.textLink{
            color: $secondaryColor;
            &:hover{
                color:$fifthColor;
                border-bottom: none;
            }
        }
    }
}

.textLeft_contentMargin{
    h2{
        margin: 30px 0;
        @include media-breakpoint-down(md){
            margin-top: 0;
        }
    }
}